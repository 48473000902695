<template>
  <section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Gestione del Personale </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item @click="$router.push('/suppliers/list')">
              Fornitori</b-breadcrumb-item
            >
            <b-breadcrumb-item active>
              Aggiungi Fornitore
             </b-breadcrumb-item
            >
          </b-breadcrumb>
        </div>
      </template>

      <div class="mt-2">
        <h2 class="text-primary-light">
          Aggiungi Fornitore
        </h2>

        <validation-observer ref="validationData">
          <b-row class="mt-2">
            <b-col md="4">
              <validation-provider
                #default="{ errors }"
                name="Ragione Sociale"
                rules="required"
              >
                <b-form-group label="Ragione Sociale">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    v-model="supplier.ragione_sociale"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <hr>

          <b-row>

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Indirizzo"
               
              >
                <b-form-group label="Indirizzo">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    v-model="supplier.address"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Nazione"
            
              >
                <b-form-group label="Nazione">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    v-model="supplier.nation"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Città"
              
              >
                <b-form-group label="Città">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    v-model="supplier.city"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Provincia"
              
              >
                <b-form-group label="Provincia">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    v-model="supplier.province"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="CAP"
              >
                <b-form-group label="CAP">
                  <b-form-input
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    v-model="supplier.zipcode"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <hr>

          <b-row>

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Partita IVA"
              >
                <b-form-group label="Partita IVA">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    type="number"
                    v-model="supplier.vat"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="email"
              >
                <b-form-group label="Email">
                  <b-form-input
                    :state="errors.length > 0 ? false : null"
                    type="email"
                    v-model="supplier.email"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Telefono Fisso"
          
              >
                <b-form-group label="Telefono Fisso">
                  <b-form-input type="number" :state="errors.length > 0 ? false : null" v-model="supplier.phone"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="Telefono Cellulare"
              
              >
                <b-form-group label="Telefono Cellulare">
                  <b-form-input type="number" :state="errors.length > 0 ? false : null" v-model="supplier.mobile_phone"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

        </validation-observer>

        <b-row>
          <b-col md="4">
            <b-button variant="primary" @click="validationData">
              Crea Account
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BSpinner,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";


import vSelect from "vue-select";

import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, email } from "@validations";

import { Requests } from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BSpinner,
    BFormGroup,
    BFormInput,
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },

  data: () => ({

    supplier: {
      ragione_sociale: '',
      address: '',
      nation: '',
      city: '',
      province: '',
      zipcode: '',
      email: '',
      phone: '',
      mobile_phone: '',
      vat: '',
    },

    locations: [],

    //Helpers
    loading: false,
    required,
    email
  }),

  created() {
    localize('it'); 

    this.getSuppliers();
  },

  methods: {
    validationData() {
      this.$refs.validationData.validate().then((success) => {
        if (success) {
          this.addSupplier();
        }
      });
    },

    async addSupplier() {
      this.loading = true;

      let request_data = new FormData;

      request_data.append('supplier_company', this.supplier.ragione_sociale);
      request_data.append('supplier_address', this.supplier.addres);
      request_data.append('supplier_nation', this.supplier.nation);
      request_data.append('supplier_city', this.supplier.city);
      request_data.append('supplier_province', this.supplier.province);
      request_data.append('supplier_zipcode', this.supplier.zipcode);
      request_data.append('supplier_email', this.supplier.email);
      request_data.append('supplier_phone', this.supplier.phone);
      request_data.append('supplier_mobile_phone', this.supplier.mobile_phone);
      request_data.append('supplier_vat', this.supplier.vat);

      try {
        const response = await Requests.addSupplier(request_data);
        console.debug(response);
      } catch(err) {
        console.debug(err)
      }

      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
