var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"text-primary"},[_vm._v("Gestione del Personale ")]),_c('b-breadcrumb',{staticClass:"ml-2"},[_c('b-breadcrumb-item',[_c('feather-icon',{attrs:{"icon":"HomeIcon","scale":"1.25","shift-v":"1.25"}})],1),_c('b-breadcrumb-item',{on:{"click":function($event){return _vm.$router.push('/suppliers/list')}}},[_vm._v(" Fornitori")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(" Aggiungi Fornitore ")])],1)],1)]},proxy:true}])},[_c('div',{staticClass:"mt-2"},[_c('h2',{staticClass:"text-primary-light"},[_vm._v(" Aggiungi Fornitore ")]),_c('validation-observer',{ref:"validationData"},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Ragione Sociale","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Ragione Sociale"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.supplier.ragione_sociale),callback:function ($$v) {_vm.$set(_vm.supplier, "ragione_sociale", $$v)},expression:"supplier.ragione_sociale"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Indirizzo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Indirizzo"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.supplier.address),callback:function ($$v) {_vm.$set(_vm.supplier, "address", $$v)},expression:"supplier.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Nazione"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nazione"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.supplier.nation),callback:function ($$v) {_vm.$set(_vm.supplier, "nation", $$v)},expression:"supplier.nation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Città"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Città"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.supplier.city),callback:function ($$v) {_vm.$set(_vm.supplier, "city", $$v)},expression:"supplier.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Provincia"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Provincia"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.supplier.province),callback:function ($$v) {_vm.$set(_vm.supplier, "province", $$v)},expression:"supplier.province"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"CAP"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CAP"}},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.supplier.zipcode),callback:function ($$v) {_vm.$set(_vm.supplier, "zipcode", $$v)},expression:"supplier.zipcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Partita IVA"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Partita IVA"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number"},model:{value:(_vm.supplier.vat),callback:function ($$v) {_vm.$set(_vm.supplier, "vat", $$v)},expression:"supplier.vat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"email"},model:{value:(_vm.supplier.email),callback:function ($$v) {_vm.$set(_vm.supplier, "email", $$v)},expression:"supplier.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Telefono Fisso"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Telefono Fisso"}},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.supplier.phone),callback:function ($$v) {_vm.$set(_vm.supplier, "phone", $$v)},expression:"supplier.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Telefono Cellulare"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Telefono Cellulare"}},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.supplier.mobile_phone),callback:function ($$v) {_vm.$set(_vm.supplier, "mobile_phone", $$v)},expression:"supplier.mobile_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validationData}},[_vm._v(" Crea Account ")])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }